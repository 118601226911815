import React, { useEffect } from 'react';
import './styles.css';
import * as posenet from '@tensorflow-models/posenet';
import { drawKeypoints, drawSkeleton } from './utilities';
import { segments } from './testaudio.json';
import { useWindowSize } from './useWindowSize';
export default function App() {
  const videoRef = React.useRef(null);
  const canvasRef = React.useRef(null);

  let currentSegmentIndex = 0;
  let currentColor = 'red';

  const { height, width } = useWindowSize();

  const mobileBreakpoint = width < 768;

  const checkSegments = (currentTime) => {
    if (currentSegmentIndex < segments.length) {
      const segment = segments[currentSegmentIndex];
      const goodIndex = segment.text.toLowerCase().indexOf('good ');
      const badIndex = segment.text.toLowerCase().indexOf('bad looks like');

      const duration = segment.end - segment.start;
      const goodTime =
        segment.start + (goodIndex / segment.text.length) * duration;
      const badTime =
        segment.start + (badIndex / segment.text.length) * duration;

      if (goodIndex !== -1 && currentTime >= goodTime) {
        currentColor = 'green';
      } else if (badIndex !== -1 && currentTime >= badTime) {
        currentColor = 'red';
      }

      if (currentTime > segment.end) {
        currentSegmentIndex++;
      }
    }
    return currentColor;
  };

  const loadVideo = async (posenet_model) => {
    if (typeof videoRef.current !== 'undefined' && videoRef.current !== null) {
      // Get Video Properties
      const video = videoRef.current;
      const videoWidth = video.width;
      const videoHeight = video.height;
      const pose = await posenet_model.estimateSinglePose(video);
      drawResult(pose, videoWidth, videoHeight);
    }
  };
  let intervalId = null;

  const runPosenet = async () => {
    const posenet_model = await posenet.load({
      inputResolution: {
        width: mobileBreakpoint ? 320 : 640,
        height: mobileBreakpoint ? 180 : 360,
      },
      architecture: 'MobileNetV1',
      outputStride: 16,
      multiplier: 1,
    });
    //
    intervalId = setInterval(() => {
      loadVideo(posenet_model);
    }, 60);
  };

  const drawResult = (pose, videoWidth, videoHeight) => {
    const canvas = canvasRef.current;
    if (!canvas) return null;
    const ctx = canvas.getContext('2d');
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const color = checkSegments(videoRef.current.currentTime);
    drawKeypoints(pose['keypoints'], 0.6, ctx, 1, color);
    drawSkeleton(pose['keypoints'], 0.7, ctx, 1, color);
  };
  // React.useEffect(() => {
  //   const video = videoRef?.current;
  //   if (!video) return;
  //   console.log(video.onLoadedData());
  //   if (video) {
  //     runPosenet();
  //   }

  //   const handlePlay = () => {
  //     runPosenet();
  //   };

  //   const handlePause = () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };

  //   video.addEventListener('play', handlePlay);
  //   video.addEventListener('pause', handlePause);

  //   // Cleanup function
  //   return () => {
  //     video.removeEventListener('play', handlePlay);
  //     video.removeEventListener('pause', handlePause);
  //   };
  // }, [videoRef, runPosenet]);

  return (
    <div
      className="App"
      style={{
        backgroundColor: '#161d29',
        width: '100%',
        height: '100%',
      }}
    >
      <video
        ref={videoRef}
        height={mobileBreakpoint ? 180 : 360}
        width={mobileBreakpoint ? 320 : 640}
        autoPlay
        muted
        playsInline
        poster="./thumbnail.png"
        placeholder="./thumbnail.png"
        preload="auto"
        onLoadedData={() => {
          runPosenet();
        }}
        style={{
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          top: mobileBreakpoint ? 250 : 400,
          textAlign: 'center',
          zindex: 9,
          width: mobileBreakpoint ? 320 : 640,
          height: mobileBreakpoint ? 180 : 360,
        }}
      >
        <source src="./DPDFullVideo.webm" type="video/webm" />
        <source src="./DPDFullVideo.mp4" type="video/mp4" />
      </video>
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          top: mobileBreakpoint ? 250 : 400,
          textAlign: 'center',
          zindex: 10,
          width: mobileBreakpoint ? 320 : 640,
          height: mobileBreakpoint ? 180 : 360,
          pointerEvents: 'none',
        }}
      />
    </div>
  );
}
